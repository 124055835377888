import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function Seo({ description, title, keywords, slug, img }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const ldJsonPrimary = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "teenyfy.",
    url: "https://www.teenyfy.com/",
    logo: "",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "",
      contactType: "technical support",
      areaServed: "GB",
    },
    sameAs: [
      "https://www.facebook.com/teenyfy",
      "https://www.instagram.com/teenyfyofficial/",
      "https://twitter.com/teenyfy/",
    ],
  };

  return (
    <Helmet htmlAttributes={{ lang: `en-US` }}>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>{title || data.site.siteMetadata.title}</title>
      <meta
        name="description"
        content={description || data.site.siteMetadata.description}
      />
      <link
        hreflang="en-us"
        rel="canonical"
        href={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      <meta name="keywords" content={keywords || ""} />
      <meta property="twitter:card" value="summary" />
      <meta property="twitter:site" value="@Teenyfy" />
      <meta property="og:site_name" content="Teenyfy" />
      <meta property="og:locale" content="en_US" />
      <meta
        property="og:title"
        content={title || data.site.siteMetadata.title}
      />
      <meta property="og:keyword" content={keywords || ""} />
      <meta property="robots" content="index,follow" />
      <meta
        property="og:description"
        content={description || data.site.siteMetadata.description}
      />
      <meta property="og:type" content="article" />
      <meta
        content="https://www.facebook.com/teenyfy"
        property="og:article:publisher"
      />
      <meta name="author" content="Shashank Jain" />
      <meta
        property="og:url"
        content={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      {img && <meta property="og:image" content={img} />}

      <script type="application/ld+json">
        {JSON.stringify(ldJsonPrimary)}
      </script>
    </Helmet>
  );
}

export default Seo;
